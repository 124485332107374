import { Router} from '@angular/router';

import {Component, OnInit} from '@angular/core';
import {PermissionService} from '../../../_services/permission.service';
import {SidebarPermissionsModel} from '../../../_models/sidebar-permissions.model';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./template-components-style.scss']
})
export class SidebarComponent implements OnInit {
    // public hidden = true;
    public user;
    public permissions = new SidebarPermissionsModel();
    public hidden = false;
    public ishovered = false;

    public logoUrl = environment.logoUrl;

    constructor(
        public router: Router,
        private permissionService: PermissionService
    ) { }

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.permissionService.sidebarPermissions().subscribe(response => {
          this.permissions = response;
        });

        this.hidden = localStorage.getItem('sidebar') !== null;
    }

    // showHideSidebar() {
    //     if (this.hidden) {
    //         document.body.className = '';
    //         this.hidden = false;
    //     } else {
    //         document.body.className = 'aside-hidden';
    //         this.hidden = true;
    //     }
    // }

    hoverOnSidebar() {
        document.body.className = 'aside-open';
        this.hidden = false;
        // localStorage.setItem('sidebar', 'open');
        this.ishovered = true;
    }

    hoverOutSidebar() {
        if(this.ishovered === true) {
            document.body.className = 'aside-hidden';
            // localStorage.removeItem('sidebar')
            this.ishovered = false;
        }
    }

    // showHideSidebar() {
    //     if (this.hidden) {
    //         document.body.className = 'aside-open';
    //         this.hidden = false;
    //         localStorage.setItem('sidebar', 'open');
    //     } else {
    //         document.body.className = 'aside-hidden';
    //         this.hidden = true;
    //         localStorage.removeItem('sidebar')
    //     }
    // }

    isRouteActive(name: string) {
        return this.router.url.split('/', 2)[1].substr(0, name.length) === name;
    }
}
