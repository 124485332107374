// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //local
  // url: 'http://localhost:8000/',
  // apiUrl: 'http://localhost:8000/api',

  //trudo
  // url: "https://jobrocks.dev.trudo.tech/",
  // apiUrl: "https://jobrocks.dev.trudo.tech/api",

  //demo
  // url: 'https://democms.job.rocks/',
  // apiUrl: 'https://democms.job.rocks/api',

  //dev
  // url: 'https://devcms.job.rocks/',
  // apiUrl: 'https://devcms.job.rocks/api',

  //prod
  url: 'https://www.cms.job.rocks/',
  apiUrl: 'https://www.cms.job.rocks/api',

  ibanApi: "https://services.job.rocks/",
  chatUrl: "http://localhost:3000/",
  chatApiUrl: "http://localhost:3000/api",

  logoUrl: "assets/images/job.rocks-grey-logo.svg",
  // logoUrl: 'assets/images/Logo_Hotelis_web_650x145px.png',
  favIconUrl: "assets/images/icons/job.rocks.favicon.svg",
  // favIconUrl: 'assets/images/Logo_Hotelis_60x60px_3.png',
  agencyName: "jobrocks",
  // agencyName: 'Hotelis',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
